exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explore-archive-js": () => import("./../../../src/pages/explore/archive.js" /* webpackChunkName: "component---src-pages-explore-archive-js" */),
  "component---src-pages-explore-category-js": () => import("./../../../src/pages/explore/category.js" /* webpackChunkName: "component---src-pages-explore-category-js" */),
  "component---src-pages-explore-index-js": () => import("./../../../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-explore-tag-js": () => import("./../../../src/pages/explore/tag.js" /* webpackChunkName: "component---src-pages-explore-tag-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-archive-page-js": () => import("./../../../src/templates/archive-page.js" /* webpackChunkName: "component---src-templates-archive-page-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-blogging-the-origin-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/blogging/the-origin/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-blogging-the-origin-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-finance-why-did-i-buy-term-insurance-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/finance/why-did-i-buy-term-insurance/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-finance-why-did-i-buy-term-insurance-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-health-2-simple-steps-to-get-up-early-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/health/2-simple-steps-to-get-up-early/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-health-2-simple-steps-to-get-up-early-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-health-bed-tea-alternative-that-worked-for-me-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/health/bed-tea-alternative-that-worked-for-me/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-health-bed-tea-alternative-that-worked-for-me-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-health-how-to-take-sunbath-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/health/how-to-take-sunbath/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-health-how-to-take-sunbath-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-3-must-have-things-for-1st-year-in-parenthood-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/3-must-have-things-for-1st-year-in-parenthood/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-3-must-have-things-for-1st-year-in-parenthood-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-healthy-night-routine-for-kids-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/healthy-night-routine-for-kids/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-healthy-night-routine-for-kids-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-help-your-kids-to-eat-independently-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/help-your-kids-to-eat-independently/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-help-your-kids-to-eat-independently-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-manage-kids-screen-time-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/manage-kids-screen-time/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-manage-kids-screen-time-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-most-effective-steps-to-make-your-kid-drink-water-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/most-effective-steps-to-make-your-kid-drink-water/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-most-effective-steps-to-make-your-kid-drink-water-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-papa-day-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/papa-day/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-papa-day-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-things-to-consider-before-teaching-new-things-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/parenting/things-to-consider-before-teaching-new-things/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-parenting-things-to-consider-before-teaching-new-things-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-self-buying-iphone-index-mdx": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/self/buying-iphone/index.mdx" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-self-buying-iphone-index-mdx" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-dutch-museum-exploration-index-mdx": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/travel/dutch-museum-exploration/index.mdx" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-dutch-museum-exploration-index-mdx" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-first-vacation-of-my-9-months-old-kid-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/travel/first-vacation-of-my-9-months-old-kid/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-first-vacation-of-my-9-months-old-kid-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-how-we-reached-to-dushsagar-falls-index-md": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/travel/how-we-reached-to-dushsagar-falls/index.md" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-how-we-reached-to-dushsagar-falls-index-md" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-konus-card-black-forest-a-step-towards-sustainability-index-mdx": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/travel/konus-card-black-forest-a-step-towards-sustainability/index.mdx" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-konus-card-black-forest-a-step-towards-sustainability-index-mdx" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-kumaon-2022-index-mdx": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/travel/kumaon-2022/index.mdx" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-kumaon-2022-index-mdx" */),
  "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-magical-cherry-blossom-tunnel-almere-index-mdx": () => import("./../../../src/templates/post-page.jsx?__contentFilePath=/opt/build/repo/content/blog/travel/magical-cherry-blossom-tunnel-almere/index.mdx" /* webpackChunkName: "component---src-templates-post-page-jsx-content-file-path-content-blog-travel-magical-cherry-blossom-tunnel-almere-index-mdx" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

